export default function () {

    const functions = useUtilityFunctions();

    function route (course, pathwaySlug) {
        const route = functions.cloneObject(course.course_route);
        if (pathwaySlug) {
            route.query = {
                context: 'pathway',
                pathwaySlug: pathwaySlug,
            };
        }
        return route;
    }

    return {route};
}
